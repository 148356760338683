.pos-product-card:hover {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
}

.pos-product-card > .ant-card-body {
	padding: 1rem !important;
	border: none !important;
	background-color: #b1b1b121;
	/* background-color: #3019341c; */
	/* background-color: #1890ff21; */
	border-radius: 6px;
}

.ant-btn-new {
	height: 33px;
	line-height: 27px;
	padding: 3px 15px;
	border-radius: 6px;
	box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
}
